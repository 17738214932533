<template>
  <div class="process-wrapper">
    <div v-if="!claimed" class="ml-6 mt-4">
      <TaskBlocker :assignee="data.assignee" />
    </div>
    <div class="prozess">
      <div v-if="!isLoading && numberBlockList">
        <h3>
          Für den Vorwahlbereich {{ nationalDestinationCode }} sind noch {{ totalPercentage }}% ({{ totalFreeNumbers }}/{{ totalNumbers }}) frei!
        </h3>

        <v-data-table
          :headers="numberBlockListHeader"
          :items="numberBlockList"
          :items-per-page="5"
          class="elevation-1 mb-4"
        >
          <template v-slot:[`item.percentage`]="{ item }">
            {{ toPercent(item.amountFree / item.totalSize) }}%
          </template>
        </v-data-table>

        <h4 class="mt-6">
          Neuen Block bei oCS schalten
        </h4>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newBlock.countryCode"
                :disabled="isSubmitting"
                label="Ländervorwahl (readonly)"
                readonly
                required
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="newBlock.areaCode"
                :disabled="isSubmitting"
                label="Vorwahl (readonly)"
                readonly
                required
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                ref="inputNumber"
                v-model="newBlock.number"
                :disabled="isSubmitting"
                :rules="numberRules"
                label="Nummer"
                required
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                ref="inputNumberDdi"
                v-model="newBlock.numberDdi"
                :disabled="isSubmitting"
                :rules="numberRules"
                numeric
                label="DDI"
                required
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                ref="inputSize"
                v-model="newBlock.size"
                :disabled="isSubmitting"
                :rules="numberRules"
                label="Blockgröße"
                required
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-menu
                ref="datePickerMenu"
                v-model="datePickerMenu"
                :close-on-content-click="false"
                :return-value.sync="newBlock.switchDate"
                :disabled="isSubmitting"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="newBlock.switchDate"
                    :disabled="isSubmitting"
                    label="Schaltdatum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newBlock.switchDate"
                  :min="new Date().toISOString().substring(0, 10)"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="datePickerMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.datePickerMenu.save(newBlock.switchDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="!isLoading" class="buttons-wrapper mt-4">
        <v-btn
          color="primary"
          large
          :disabled="!claimed"
          :loading="isSubmitting"
          @click="assignRnb()"
        >
          Block schalten
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ActionRufnummernBeiBNetzABestellenHumanTask',
  components: {
    TaskBlocker: () => import('../TaskBlocker.vue'),
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
    DisplayContainer: () =>
      import('@/components/elements/DisplayContainer.vue'),
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      isSubmitting: false,
      datePickerMenu: false,
      nationalDestinationCode: 0,
      numberBlockList: null,
      totalNumbers: 0,
      totalFreeNumbers: 0,
      totalPercentage: 0.0,
      newBlock: {
        countryCode: '49',
        areaCode: '',
        number: '',
        numberDdi: '',
        size: 1000,
        switchDate: new Date().toISOString().substring(0, 10)
      },
      numberBlockListHeader: [
        {
          text: 'Vorwahl',
          align: 'start',
          sortable: false,
          value: 'nationalDestinationCode',
        },
        {
          text: 'Rufnummer',
          align: 'start',
          sortable: false,
          value: 'subscriberNumber',
        },
        {
          text: 'Blockgröße',
          align: 'start',
          sortable: false,
          value: 'totalSize',
        },
        {
          text: 'Nummern in Quarantäne',
          align: 'start',
          sortable: false,
          value: 'amountQuarantined',
        },
        {
          text: 'Anzahl freier Nummern',
          align: 'start',
          sortable: false,
          value: 'amountFree',
        },
        {
          text: '% frei',
          align: 'start',
          sortable: false,
          value: 'percentage',
        },
      ],
      numberRules: [
        v => !!v || 'Notwendiges Feld',
        v => /^\d+$/.test(v) || '',
        v => v > 0 || 'Muss größer 0 sein',
      ],
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
  },
  mounted: function () {
    this.loadTaskData();
  },
  methods: {
    loadTaskData: async function () {
      this.isLoading = true;
      await this.data.variables.then((data) => {
        this.nationalDestinationCode = data.nationalDestinationCode.value;
        this.newBlock.areaCode = this.nationalDestinationCode;
        this.isLoading = false;
      });
      this.fetchNumberList();
    },
    fetchNumberList: async function () {
      HTTP.get(`/numberBlock/byNdc/${this.nationalDestinationCode}`)
        .then(({ data }) => {
          this.totalNumbers = data.reduce((partialTotal, row) => partialTotal + row.totalSize, 0);
          this.totalFreeNumbers = data.reduce((partialTotal, row) => partialTotal + row.amountFree, 0);
          this.totalPercentage = this.toPercent(this.totalFreeNumbers / this.totalNumbers);
          this.numberBlockList = data;
        })
        .catch((err) => {
          this.showToast('Fehler beim laden der Prozessdaten');
          console.error(err);
        });
    },
    assignRnb: async function () {
      if (!this.validateInputs()) return;
      this.isSubmitting = true;
      const newBlock = { ...this.newBlock };
      newBlock.switchDate += 'T06:00:00.000Z';
      HTTP.post(`/numberBlock/assignNewNumberBlock/${this.data.id}`, newBlock)
        .then(({ data }) => {
          this.showToast('Die Aufgabe wurde erfolgreich abgeschlossen', 'info');
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(err => {
          this.showToast('Fehler beim abschließen der Aufgabe: ' + err);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    validateInputs() {
      let allInputsValid = true;
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith('input')) {
          const inputValid = this.$refs[ref].validate(true);
          allInputsValid = allInputsValid && inputValid;
        }
      });
      return allInputsValid;
    },
    toPercent(number) {
      return (Math.round(number * 10000) / 100);
    },
    showToast(message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    }
  }
}
</script>
<style scoped>
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}
</style>
